import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  margin-top: 10rem;
  ${MEDIA.TABLET`
    display: block;
    padding: 0rem;
    margin-top: 15rem;
  `};
  ${MEDIA.PHONE`
    margin-top: 10rem;
    padding: 0 3rem;
  `};
`;
