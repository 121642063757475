import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Gallery from 'components/gallery';
import Head from 'components/head';
import Footer from 'components/footer';

const Work = ({ data }) => (
  <Layout>
    <Head pageTitle={data.workJson.title} />
    <Gallery items={data.workJson.gallery} />
    <Footer />
  </Layout>
);

Work.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Work;

export const query = graphql`
  query WorkQuery {
    workJson {
      title
      gallery {
        title
        copy
        tag
        url
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
