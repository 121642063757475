import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import {
  ImgContainer,
  TextProject,
  Title,
  Copy,
  Tag,
  ProjectLink,
} from './item.css';

const Item = ({ title, copy, tag, image, url }) => (
  <figure>
    <Fade bottom duration={1000} delay={200} distance="50px">
      <ImgContainer>
        <Link to={url} aria-label="Project Link" rel="noopener noreferrer">
          <Img fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
        </Link>
      </ImgContainer>
    </Fade>
    <TextProject>
      <figcaption>
        <Fade bottom duration={1000} delay={200} distance="50px">
          <Title>{title}</Title>
          <Copy>{copy}</Copy>
          <Tag>{tag}</Tag>
          <ProjectLink>
            <Link to={url} aria-label="Project Link" rel="noopener noreferrer">
              See project
            </Link>
          </ProjectLink>
        </Fade>
      </figcaption>
    </TextProject>
  </figure>
);

Item.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  tag: PropTypes.string,
  image: PropTypes.object.isRequired,
  url: PropTypes.string,
};

export default Item;
