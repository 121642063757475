import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const ImgContainer = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
`;

export const TextProject = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20rem;
`;

export const Title = styled.p`
  display: block;
  font-weight: 600;
  font-size: 1.8rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
  ${MEDIA.PHONE`
    font-size: 1.6rem;
  `};
`;

export const Copy = styled.p`
  font-size: 1.4rem;
  ${MEDIA.PHONE`
    font-size: 1.2rem;
  `};
`;

export const Tag = styled.p`
  font-size: 1.4rem;
  font-style: italic;
  margin-bottom: 4rem;
  ${MEDIA.PHONE`
    font-size: 1.2rem;
  `};
`;

export const ProjectLink = styled.div`
  a {
    display: inline-block;
    position: relative;
    text-align: center;
    text-decoration: none;
    color: #OOO;
    overflow: hidden;
    padding: 14px;
    font-size: 1.4rem;
    ${MEDIA.PHONE`
      font-size: 1.2rem;
    `};
  }

  a:after {
    content: '';
    position: absolute;
    background-color: #000;
    height: 1px;
    width: 0%;
    transform: translateX(-50%);
    left: 50%;
    bottom: 0;
    transition: 0.35s ease;
  }

  a:hover:after {
    width: 60%;
  }
`;
